/* ----------------------- Common desktop header ----------------------- */
.header {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 15px 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: var(--header-height);
  overflow: hidden;

  hr {
    border-color: #e8e8e8;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 30px);
    z-index: -1;
  }

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  .header-wr {
    width: 100%;
    position: relative;
    /*z-index: 111;*/
  }

  &.scrolled {
    .header-wr {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 10;
      height: 70px;
      animation: slideDown 0.3s forwards;
      background: var(--light-gray);
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      display: flex;

      .cont {
        display: flex;
        align-items: center;
      }
    }
  }

  .logo {
    height: 100%;
    width: 200px;
    max-height: 43px;
    z-index: 0;
    margin-right: ac(33px, 15px, 1024, 1440);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;
    z-index: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    &-item {
      margin: 0 13px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: 0.9em;
    line-height: 1.11;
    font-weight: 900;
    font-family: var(--font-main);
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 10px 0;

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--cl-grey);
      transition: all 0.3s ease;
    }

    &:hover {
      color: var(--cl-red);

      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-red);
      }
    }
  }
}
.close-menu{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 28px;
  height: 28px;
  z-index: 3;
  color: var(--white);
  opacity: 0.6;
  cursor: pointer;
  transition: 0.3s;
  display: none;
  &:hover{
    opacity: 1;
  }


}

/* end of Common desktop header */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  width: 34px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--cyan);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  margin-left: auto;
  font-size: 14px;
  cursor: pointer;
}

.header-close-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: rgba(10, 0, 12, 0.85);
  pointer-events: none;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 0;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* end of HEADER COMPONENTS */
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: none;
  }
}

@media (max-width: 1384px) {
  .header .navbar-nav {
    padding-top: 16px;
  }
}

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .close-menu{
     display: block;
  }
  .header {
    --header-height: 70px;
    .burger,
    .menu-toggle {
      display: flex;
    }


    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      min-width: 320px;
      padding: 0;
      overflow: hidden;
      justify-content: center;
      flex-direction: column;
      padding-top: 50px;
      display: none;
      transition: opacity 0.2s;

      &.active {
        animation: fadeIn forwards 0.3s;
        display: flex;
      }
      .menu-wr{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        overflow-y: auto;
        scrollbar-width: 15px;
        scrollbar-color: #C7C7C7;
        z-index: 1;
        pointer-events: none;
        &::-webkit-scrollbar {
          width: 16px;

        }

        &::-webkit-scrollbar-track {
          background: var(--white);
          border-radius: 0;
          border-left: 1px solid #C7C7C7;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #C7C7C7;
          border: 4px solid transparent;
          background-clip: content-box;
          border-radius: 20px;
          &:hover{
            background-color: #878787;
          }
        }
      }

      .menu {
        width: fit-content;
        margin: 0 auto;
        max-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding: 50px 0;
        align-items: flex-start;
        position: relative;
        z-index: 2;
        flex-wrap: nowrap;
        pointer-events: all;
      }

      .menu-link {
        padding: 15px 30px;
        margin: 0;
        text-align: center;
        font-family: var(--font-archivo);
        color: hsla(0, 0%, 100%, 0.8);
        font-size: 1.5em;
        font-weight: 900;
        letter-spacing: 1.3px;
        line-height: 1.6;
      }

      .menu-item {
        margin: 0;
        width: 100%;
        transform: translateY(-15px);
        opacity: 0;

        will-change: transform, opacity;
        animation: fadeDown 0.5s forwards;
        animation-delay: 0.2s;

        &:last-of-type {
          width: fit-content;
          margin: 0 auto;
          padding: 15px 0;
        }

        &:nth-of-type(2) {
          animation-delay: 0.3s;
        }

        &:nth-of-type(3) {
          animation-delay: 0.4s;
        }

        &:nth-of-type(4) {
          animation-delay: 0.5s;
        }

        &:nth-of-type(5) {
          animation-delay: 0.6s;
        }

        &:nth-of-type(6) {
          animation-delay: 0.7s;
        }

        &:nth-of-type(7) {
          animation-delay: 0.8s;
        }

        &:nth-of-type(8) {
          animation-delay: 0.9s;
        }
      }

      @mixin max-sm {
        width: 100%;

        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 80px;
          width: calc(100% - 25.6vw);
          background: var(--cl-light-grey);
        }
      }
    }
    .menu-wr{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeDown {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100%{
    transform: none;
    opacity: 1;
  }
}
