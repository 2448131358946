@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;900&display=swap');

:root {
  --fz: 16px;
  --light-gray: #F5F5F5;
  --dark-gray-rgb: rgba(16, 21, 43, 0.10);
  --dark-gray: #777777;
  --white: #ffffff;
  --black: #0A091F;
  --cyan: #75EDD4;
  --orange: #E74A2F;
  --purple: #5928F4;
  --font-main: 'Inter', sans-serif;
  --font-archivo: 'Archivo', sans-serif;
  --header-height: 160px;

  /* --mobile-menu-start-point and @define-mixin --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 849;
  --vh: calc(100vh / 100);
}


/* --mobile-menu-start-point and @define-mixin --mobile-menu-start-point must be the same */
@define-mixin mobile-menu-start-point {
  @media (max-width:849px) {
    @mixin-content;
  }
}
/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @define-mixin --mobile-menu-start-point */
@define-mixin mobile-menu-end-point {
  @media (min-width:850px) {
    @mixin-content;
  }
}

