html{
  font-size: var(--fz);
  color: var(--black);
  line-height: 1.5;
  background: var(--light-gray);
  font-family: var(--font-main);
  *{
    box-sizing: border-box;
  }
  scrollbar-width: 15px;
  scrollbar-color: #C7C7C7;
  &::-webkit-scrollbar {
    width: 16px;

  }

  &::-webkit-scrollbar-track {
    background: var(--white);
    border-radius: 0;
    border-left: 1px solid #C7C7C7;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C7C7C7;
    border: 4px solid transparent;
    background-clip: content-box;
    border-radius: 20px;
    &:hover{
      background-color: #878787;
    }
  }

}
h1,
h2,
h3,
h4 {
  font-weight: 500;
}

h1 {
  font-size: ac(50px, 35px);
  font-weight: 900;
}

h2 {
  font-size: ac(50px, 27px);
}

h3 {
  font-size: ac(25px, 22px);
  line-height: normal;
}

h4 {
  font-size: ac(20px, 18px);
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;
}



.content-element {
  h1{
    font-size: ac(34px, 26px);
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 16px;

  }
 h2{
   font-size: ac(24px, 22px);
   font-weight: 900;
   text-transform: uppercase;
   margin-bottom: 16px;
   line-height: 1;
 }
  h3{
    font-size: ac(22px, 20px);
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  h4 {
    font-weight: 900;
    font-size: 18px;
  }
  p{
      margin-bottom: ac(38px, 25px);
      font-weight: 500;
      font-size: ac(18px, 16px);
      line-height: 1.35;
  }
    a{
        display: inline;
        text-decoration: underline;
        font-size: inherit;
        transition: color 0.3s;
        &:hover{
            color: var(--purple);
        }

    }

  ol,
  ul {
      padding-left: 20px;
      margin-bottom: 25px;
      li{
          margin-bottom: 3px;
          &:last-of-type{
              margin-bottom: 0;
          }
      }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}

@media (max-width: 370px) {
    h1{
        font-size: 28px;
    }
}