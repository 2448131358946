.page-back-link {
  margin-bottom: 28px;
}

.candidate-top-section {
  overflow: visible;
  padding-top: 52px;
  padding-bottom: ac(76px, 40px);

  & + .video-section {
    margin-top: ac(-13px, 0px);
  }
}

.candidate-title {
  background: var(--purple);
  color: var(--white);
  position: relative;
  border-radius: 9px;
  max-width: 1263px;
  margin-right: 118px;
  padding: ac(50px, 20px, 850, 1440);

  h1 {
    line-height: 118%;
    font-family: var(--font-archivo);
  }

  .banner-label {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(73%, -69%);
  }
}

.candidate-content {
  &-wrapper {
    display: flex;
    gap: 28px;
    justify-content: space-between;
    max-width: 1263px;
    margin-right: 118px;
  }

  &-main {
    flex-grow: 1;
    max-width: 747px;
    padding-left: ac(50px, 20px, 850, 1440);
    padding-top: 6px;

    .content-element {
      padding-bottom: 20px;
    }
  }

  &-aside {
    width: ac(437px, 310px, 1024, 1440);
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    &-icon {
      width: calc(100% + 50px);
      max-width: 112%;
      margin-right: ac(-50px, 0px);
    }
  }
}

.candidate-info {
  max-width: 680px;

  &-thumbnail-wr {
    display: flex;
    align-items: center;
    font-size: ac(24px, 20px);
    font-weight: 900;
    margin-bottom: 40px;
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    width: 60px;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 50%;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ac(38px, 25px) 20px;
    margin-bottom: 40px;


    li {
      font-size: ac(18px, 16px);
      font-weight: 500;

      div {
        font-size: ac(24px, 20px);
        font-weight: 900;
        line-height: 1;
        margin-bottom: 14px;
        text-transform: uppercase;
      }
      a{
        word-break: break-word;
      }
    }
  }
}

.next-step-link {
  display: inline-flex;
  flex-direction: column;
  font-size: ac(50px, 30px);
  color: var(--purple);
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  font-family: var(--font-archivo);

  img {
    width: ac(237px, 150px);
    margin-left: 5px;
    margin-bottom: ac(45px, 20px, 850, 1440);
  }
}

.consultant-card {
  width: 283px;
    max-height: 100%;
  margin-bottom: ac(112px, 50px, 1024, 1440);

  &-title {
    font-size: ac(24px, 20px);
    font-weight: 900;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 9px;
    margin-bottom: 22px;

    &::before {
      content: '';
      display: block;
      padding-top: 114%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-name {
    font-size: ac(24px, 20px);
    font-weight: 700;
    line-height: 1;
    margin-bottom: ac(28px, 20px);
  }

  &-info {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 28px;

    li {
      margin-bottom: 7px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a{
      word-wrap: break-word;
    }
  }

  &-footer{
    display: inline-block;
    max-width: 100%;
    h3{
      margin-bottom: 20px;
    }
    .btn{
      min-height: ac(54px, 44px);
      min-width: ac(190px, 140px);
      font-size: ac(18px, 14px);
      text-align: center;
      line-height: 1;
      margin-bottom: 15px;

    }
  }
}

.contact-banner {
  width: 680px;
  max-width: 55%;
  margin-left: auto;
  margin-right: ac(116px, 0px);
  margin-top: ac(-85px, -61px, 680, 1440);
  font-size: ac(50px, 35px);
  text-transform: uppercase;
  font-weight: 900;
  font-family: var(--font-archivo);
  position: relative;
  padding: 20px 0;
  line-height: 1.1;

  span {
    color: var(--orange);
  }

  a {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(5%, -55%);
    max-width: 25%;
    min-width: 120px;
  }
}

.looking-section {
  padding-top: 60px;

  &-banner {
    color: var(--white);
    padding: ac(50px, 30px, 850, 1440);
    background: var(--black);
    width: 579px;
    max-width: 50%;
    border-radius: 9px;
    transform: rotate(-3deg);
    font-size: ac(40px, 28px);
    line-height: 110%;
    font-family: var(--font-archivo);
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: ac(-106px, -50px);
    margin-left: 20px;
  }

  &-content {
    background: var(--black);
    color: var(--white);
    padding-top: ac(157px, 85px, 850, 1440);
    padding-bottom: ac(100px, 50px);

    .cont {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &-title-wr {
    padding-left: ac(68px, 0px);
  }

  &-title {
    text-transform: uppercase;
    font-family: var(--font-archivo);
    font-weight: 900;
    line-height: 110%;
    margin-bottom: 30px;

    span {
      color: var(--cyan);
    }
  }

  &-thumbnail {
    position: relative;
    overflow: hidden;
    transform: rotate(10.46deg);
    width: 361px;
    max-width: 29%;
    margin-right: 50px;
    margin-top: ac(-202px, -100px);
    flex-shrink: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 114.12%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-bg {
    position: relative;
    overflow: hidden;
    min-height: 180px;

    &::before {
      content: '';
      display: block;
      padding-top: 25%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.video-section {
  padding-bottom: ac(55px, 35px);

  hr {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 1px solid var(--dark-gray-rgb);
  }

  .cont {
    display: flex;
    justify-content: space-between;
    gap: ac(30px, 20px);
    position: relative;
    align-items: flex-start;
    padding-bottom:  ac(59px, 35px);
  }

  .video-icon {
    flex-shrink: 0;
    width: ac(208px, 50px);
  }
}

.video-wr {
  max-width: 913px;
    flex-grow: 1;
  position: relative;
  margin-right: 118px;
    cursor: pointer;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
    &::before{
        content: '';
        display:block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: var(--black);
        opacity: 0.6;
    }

  .video-icon-small {
    position: absolute;
    right: ac(30px, 10px);
    top: ac(30px, 10px);
    z-index: 2;
    max-width: 8%;
  }

    &-thumbnail{
        position: relative;
        overflow: hidden;
        z-index: 0;
      min-height: 170px;

        &::before{
            content: '';
            display:block;
            padding-top: 38.33%;
        }
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-play-icon{
        width: ac(88px, 44px);
        height: ac(88px, 44px);
        color: var(--white);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--white);
          font-size: ac(37px, 17px);
      padding-left: ac(9px, 5px);
      border-radius: 50%;
      transition: all 0.3s;

    }
    &:hover{
        .video-wr-play-icon{
            color: var(--white);
          border-color: var(--cyan);
          background: var(--cyan);
        }
    }
    
}

@media (max-width: 1024px) {
  .candidate-title {
    margin-right: 0;

    .banner-label {
      right: 80px;
      width: 120px;
      top: -10px;
    }
  }

  .candidate-content {
    &-wrapper {
      margin-right: 0;
    }

    &-aside-icon {
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
  }
}

@media (max-width: 849px) {
  .candidate-content {
    &-main {
      padding-left: 0;
    }

    &-wrapper {
      flex-direction: column;
    }
  }

  .candidate-content {
    .next-step-link {
      margin: 0 auto;
    }

    &-aside {
      width: 375px;
      max-width: 100%;
      align-items: center;
      margin: 0 auto 35px;

      &-icon {
        width: 70%;
      }
    }
  }

  .consultant-card-btn {
    width: 190px;
  }

  .contact-banner {
    width: fit-content;
    max-width: 100%;
  }

  .looking-section {
    &-banner {
      margin-left: auto;
      margin-right: auto;
      width: 400px;
      max-width: 100%;
    }

    &-content {
      .cont {
        flex-direction: column-reverse;
      }
    }

    &-thumbnail {
      align-self: center;
      margin-top: 20px;
      margin-bottom: 45px;
      max-width: 75%;
      width: 290px;
      margin-right: 0;
    }

    &-title-wr {
      width: 520px;
      max-width: 100%;
      margin: 0 auto;
      padding: 0;
      text-align: center;
    }
  }
}

@media (max-width: 680px) {
  .contact-banner {
    margin-top: 0;
  }

  .looking-section {
    padding-top: 30px;
  }
  .candidate-info-list {
    grid-template-columns:auto auto;
  }
}



.video-popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
   /* display: none;*/

  &-close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    right: 15px;
    top: 15px;
    color: var(--white);
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: var(--orange);
    }
  }

  &-underlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: var(--black);
    opacity: 0.3;
  }

  .plyr {
    width: 900px;
    max-width: 90%;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    z-index: 1;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.5);
    background: var(--black);

    &__video-wrapper {
      position: static;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 56.22%;
    }

    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__poster {
      -webkit-background-size: cover;
      background-size: cover;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
        background: var(--black);
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
}

@media (max-width: 370px) {
    .page-back-link{
        margin-bottom: 83px;
    }
    .candidate-title .banner-label{
        right: 0;
        transform: translate(0, -75%);

    }
    .contact-banner {
        margin-top: 70px;
        a{
            transform: translate(0%, -55%);
        }
    }

}