
.meta-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    label {
        font-family: var(--font-main);
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
        color: var(--black);
        padding-bottom: 8px;
        cursor: pointer;
    }

    select {
        opacity: 0;
        height: ac(50px, 48px);
    }

    .choices {
        width: 100%;
        margin-bottom: 0;
        overflow: visible;
        height: 100%;

        &__inner {
            border: 0;
            border-radius: 0;
            outline: none;
            transition: box-shadow 0.3s ease, border-color 0.3s ease,
            border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
            padding: 0 0 0 ac(16px, 12px)!important;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @mixin max-line-length-one;
            width: 100%;
            cursor: pointer;
            font-size: 18px;
            line-height: 1.2;
            font-weight: 500;
            font-family: var(--font-main);
            color: var(--black);
            height: 100%;

            &.error {
                border-color: var(--orange);
            }
        }
        &.is-open {
            &:not(.is-flipped) {
                .choices__inner {
                    border-radius: 6px 6px 0 0;
                }

                .choices__list--dropdown,
                .choices__list[aria-expanded] {
                    border-top: none;
                    margin-top: 0;
                    border-radius: 0 0 6px 6px;
                }
            }

            &.is-flipped {
                .choices__inner {
                    border-radius: 0 0 6px 6px;
                }

                .choices__list--dropdown,
                .choices__list[aria-expanded] {
                    margin-bottom: 0;
                    border-bottom: none;
                    border-radius: 6px 6px 0 0;
                    box-shadow: 0 0 5px 0 rgba(0,0,0,0.16);
                }
            }
        }

        &__item {
            @mixin max-line-length-one;
        }

        &__placeholder {
            color: var(--black);

            opacity: 1;
        }

        &__list {
            padding: 0;
            background-color: var(--light-gray);

            .choices__item {
                padding-right:21px;
                font-size: 18px;
                line-height: 1;
                font-weight: 400;
                font-family: var(--font-main);

                &.is-selected {
                    color: var(--black);
                }
            }

            &--multiple {
                color: var(--purple);
                .choices__item--selectable {
                    display: none;
                }

                + .choices__input {
                    display: none;
                }
            }

            &--dropdown {
                .choices__item {
                    color: var(--black);
                }
            }
        }

        &[data-type*='select-one'],
        &[data-type*='select-multiple'] {
            cursor: pointer;
            &:after {
                border: none;
                border-bottom: 1px solid var(--black);
                border-right: 1px solid var(--black);
                content: '';
                display: block;
                height: 5px;
                margin-top: -4px;
                pointer-events: none;
                position: absolute;
                right: ac(12px, 6px);
                top: 53%;
                transform-origin: 66% 66%;
                transform: rotate(45deg) scale(1.5);
                transition: all 0.15s ease-in-out;
                width: 5px;
            }
        }

        &.is-open {
            &:after {
                transform: rotate(-135deg) scale(1.5);
            }
        }

        &__list--dropdown .choices__item--selectable.is-highlighted,
        &__list[aria-expanded] .choices__item--selectable.is-highlighted {
            background-color:var(--purple);
            color: var(--white);

            &:not(.is-selected) {
                color: var(--white);
            }
        }

        &__list--dropdown,
        .choices__list[aria-expanded] {
            border: 2px solid var(--light-gray);
            z-index: 20;
            border-radius: 6px;
        }

        &[data-type*='select-one'] .choices__input {
            margin: 2px;
            max-width: calc(100% - 4px);
            border: 2px solid var(--dark-gray-rgb);
            border-radius: 8px;
            background: transparent;
            color: var(--black);
            font-size: 18px;
            line-height: 1.2;
            font-weight: 500;
            font-family: var(--font-main);
        }

        &[data-type*='select-multiple'] .choices__inner {
            cursor: pointer;
        }
    }

    &__list {
        display: none;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-width: 100%;
        grid-column-gap: 10px;
        grid-row-gap: 8px;

        &.is-visible {
            margin-top: 10px;
            display: flex;
        }
    }

    &__list-item {
        position: relative;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 500;
        font-family: var(--font-main);
        padding-right: 14px;
        cursor: default;
        color: var(--black);

        &:after {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            transition: background-color 0.25s ease, opacity 0.25s ease,
            transform 0.25s ease;
            opacity: 0.5;
            background: var(--purple);
            clip-path: polygon(
                    20% 0%,
                    0% 20%,
                    30% 50%,
                    0% 80%,
                    20% 100%,
                    50% 70%,
                    80% 100%,
                    100% 80%,
                    70% 50%,
                    100% 20%,
                    80% 0%,
                    50% 30%
            );
        }

        &:hover {
            &:after {
                opacity: 1;
                transform: translateY(-50%) scale(1.15);
            }
        }
    }
    &__help,
    &__error {
        margin-top: 5px;
        font-family: var(--font-main);
        font-size:18px;
        line-height: 1.2;
        font-weight: 600;
    }

    &__help {
        color: var(--purple);
    }

    &__error {
        color: var(--orange);
    }
}


