.footer {
  background: var(--black);
  color: #f1f1f1;
  &-section{
    padding: 48px 0 ac(120px, 60px);
  }
  .cont{
    display: flex;
    justify-content: space-between;
  }
  &-logo-wr{
    flex-shrink: 0;
    width: 410px;
    max-width: 30%;
    margin-right: ac(60px, 46px, 850, 1440);
    a{
      display: inline-block;
      max-width: 48%;
      img{
        width: 100%;
      }
      @media (max-width: 650px){
        max-width: 40.4%;
        }
    }
  }
  &-logo{
    width: 246px;
    max-height: 53px;
    margin-bottom: 28px;
    max-width: 62.1%;
    @media (max-width: 650px){
      max-width: 56.1%;
      }
  }
  &-motto{
    line-height: 1.54;
    margin-bottom: 22px;
    letter-spacing: 0.1px;
  }
  &-nav{
    flex-grow: 1;
    p{
      font-family: var(--font-archivo);
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 12px;
      font-weight: 900;
    }
  }
  &-links-wr{
    display: flex;
    align-items: flex-start;
  }
  &-links{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 26px ac(142px, 13px, 850, 1440);
    margin-right: ac(194px, 90px);
    li{
      font-size: 13px;
      font-weight: 500;
      line-height: 1.5923;
    }

  }
  &-info{
    display: grid;
    grid-row-gap: 26px;
    font-size: 13px;
    font-weight: 500;
    flex-shrink: 0;
    margin-right: ac(0px, 44px, 850, 1440);
    li{
      &:last-of-type{
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 849px) {
  .footer {
    &-logo-wr{
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      padding-right: 40px;
      padding-bottom: 30px;
    }
    &-section{
      .cont{
        flex-direction: column;
      }
    }
    &-links-wr{
      justify-content: space-between;
    }
    &-links{
      width: 66.66%;
      margin-right: 0;
    }
    &-info{
    flex-grow: 1;
      margin-right: 0;
    }
  }
}

@media (max-width: 550px) {
  .footer{
    &-logo-wr{
      a{
        width: 59.143%;
        max-width: 100%;
      }
    }
    &-motto{
      line-height: 1.6;
    }
    &-links-wr{
      flex-wrap: wrap;
      gap: 45px;
    }
    &-links{
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
  }
}
