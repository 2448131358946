input, textarea{
    @mixin transparent-bg-input var(--black);
    background: transparent;
    font-size: 18px;
    letter-spacing: 0.9px;
    font-weight: 400;
    font-family: var(--font-main);
    &::placeholder{
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        opacity: 0.8;
    }
}