.btn{
    padding: 8px 22px;
    background: var(--black);
    color: var(--white);
    border-radius: 9px;
    cursor: pointer;
    font-size: 13.2px;
    font-weight: bold;
    min-height: 2.5em;
    line-height: 2.4em;
    flex-shrink: 0;
    letter-spacing: 0.03em;

    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    &.btn-purple{
        background: var(--purple);
    }
}
.link-btn{
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    font-size: 17px;
    cursor: pointer;
    transition: opacity 0.3s;
    width: fit-content;
    &-arrow{
        position: relative;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        background: var(--cyan);
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: ac(34px, 24px);

        i{
            display: inline-block;
            font-size: 170%;
            transform: translateX(83%);
            transition: transform 0.3s, color 0.3s, opacity 0.3s;
        }
    }
    &:hover{
        opacity: 0.7;
        /*.submit-btn-arrow{
            color: var(--white);
            i{
                transform:none;
            }
        }*/
    }
    &.link-btn-white{
        color: var(--white);
    }
}

.more-btn{
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    font-size: 17px;
    padding: 0 10px;
    cursor: pointer;
    width: fit-content;
    transition: opacity 0.3s;
    &-arrow{
        position: relative;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        background: var(--orange);
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 34px;

        i{
            display: inline-block;
            font-size: 170%;
            transform: translateX(83%);
            transition: transform 0.3s, color 0.3s, opacity 0.3s;
        }
    }
    &:hover{
        opacity: 0.7;
        /*.more-btn-arrow{
            color: var(--white);
            i{
                transform:none;
            }
        }*/

    }
}

