.fp-watermark {
    display: none;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    min-height: calc(100 * var(--vh));
    display: flex;
    flex-direction: column;
}

.swiper-wrapper {
    left: 0 !important;
}

#site {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main {
    flex-grow: 1;
}

.cont {
    margin: 0 auto;
    width: 100%;
    max-width: 1410px;
    padding: 0 15px;
}

section {
    position: relative;
    overflow: hidden;
    width: 100%;
}

#bold-credits {
    width: 148px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }

    @mixin max-sm {
        width: 159px;
    }
}

#site.cookie-terms-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .main {
        flex-grow: 1;
        padding: 0;
    }
}

.cookie-terms-section {
    padding: ac(250px, 140px) 0 ac(150px, 50px);

    p {
        padding: 0;
        margin-bottom: 10px;
    }

    ul,
    ol {
        padding-left: 20px;

        li {
            p {
                margin-bottom: 0;
            }
        }
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    a {
        display: inline;
        text-decoration: underline;
        transition: 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }
}

.single-page-404 {
    min-height: 100vh;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1,
    p {
        margin-bottom: 15px;
        padding: 0;
    }

    a {
        display: inline-block;
        margin-top: 20px;
    }
}

.swiper-pagination {
    position: relative;
}

.swiper-pagination-bullet {
    --swiper-pagination-color: var(--black);
    width: 40px;
    height: 6px;
    border-radius: 0;
    margin: ac(81px, 40px) 5px 0 5px !important;
    display: inline-block;
}

.sign-up-section {
    padding: 62px 0 ac(95px, 65px);
    color: var(--white);
    background: var(--black);
    border-bottom: 1px solid var(--white);

    .title-wr {
        max-width: 50%;
    }

    .title {
        font-size: 100px;
        margin-right: 25px;
        font-family: var(--font-archivo);
        font-weight: 900;
        line-height: 0.85;
    }

    a {
        display: inline-block;
        width: 210px;
        max-width: 15.7%;

        img {
            width: 100%;
        }

        @media (max-width: 1300px) {
            max-width: 14.7%;
        }
    }

    &-label {
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 14px;
        color: #f1f1f1;
    }

    .cont {
        display: flex;
        justify-content: space-between;
    }
}

[data-aos='flipInX'] {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transform: perspective(400px) rotateX(90deg) translate3d(0, -15px, 0);

    &.aos-animate {
        animation-delay: 0.1s;
        opacity: 1 !important;
        transform: translateZ(0) scale(1);
    }
}

@media (max-width: 849px) {
    .sign-up-section a {
        width: ac(340px, 194px, 375, 849);
        max-width: 100%;
    }
}

[data-paralax] {
    opacity: 0;
}

@media (max-width: 550px) {
    .sign-up-section {
        .title-wr {
            width: 100%;
            max-width: 100%;
            padding-bottom: 50px;
        }

        .title {
            font-size: 50px;

            br {
                display: none;
            }
        }

        .cont {
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

.plyr-video-item {
}

.plyr {
    --plyr-color-main: var(--cyan);
    --plyr-control-icon-size: ac(36px, 26px);

    &__controls {
        opacity: 0;
        visibility: hidden;
    }

    &.started {
        .plyr__controls {
            opacity: 1;
            visibility: visible;
        }
    }

    &__control.plyr__control--overlaid {
        border: 2px solid var(--white);
        background: transparent;
        width: ac(88px, 58px) !important;
        height: ac(88px, 58px) !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin-left: 2px;
    }
}

.not-found-title {
    font-size: ac(25px, 22px);
    text-align: center;
    padding: 35px 0;
}

.head-block {
    padding: ac(120px, 60px) 0 30px;

    .title {
        margin-bottom: ac(30px, 25px);
    }

    &-motto {
        font-size: ac(24px, 20px);
        line-height: 1.75;
        max-width: 900px;
        font-weight: 600;
    }

    & + .job-results-section {
        .job-results-grid {
            padding-top: 0;
        }
    }
}