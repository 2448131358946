.top-banner-section {
    padding: 18px 0;

    .cont {
        display: flex;
        gap: 25px;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-family: var(--font-archivo);
        line-height: 110%;
        padding-top: 50px;
    }
}

.banner-label {
    position: relative;
    overflow: hidden;
    width: 161px;
    flex-shrink: 0;
    align-self: center;
    font-family: var(--font-archivo);
    font-size: ac(50px, 35px);
    font-weight: 900;
    margin-right: ac(38px, 0px);
    transition: transform 0.3s, filter 0.3s;
    will-change: filter, transform;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0));

    img {
        width: 100%;
        height: auto;
    }


    /*&:hover {
      transform: scale(1.2);
      filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
    }*/
}

.profiles-section {
    padding: 73px 0 89px;
    overflow: hidden;
}

.profiles-swiper-wr {
    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        width: fit-content;

        &:nth-of-type(even) {
            .profile-card {
                transform: rotate(3deg);

                &-icon {
                    transform: rotate(-3deg);
                }
            }
        }
    }
}

.profile-card {
    max-width: 100%;
    width: ac(315px, 250px);
    margin: 0 auto;
    transform: rotate(-3deg);
    border-radius: 9px;
    position: relative;
    color: var(--white);
    padding: 20px ac(34px, 16px) 30px 16px;
    min-height: ac(366px, 280px);
    display: flex;
    cursor: pointer;

    &-thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
        border-radius: inherit;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            background: rgba(10, 9, 31, 0.6);
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
            transition: transform 0.5s;
            transform-origin: 0 40%;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 21px;
        transform: rotate(1deg);
        margin-left: 2px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-title {
        font-size: ac(30px, 22px);
        font-weight: 700;
        margin-bottom: 25px;
        line-height: 1.2;
        @mixin max-line-length 5;
    }

    &-footer {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        gap: 25px;
        font-size: 25.6px;
        align-items: flex-end;
        font-family: var(--font-archivo);
        padding-left: ac(4px, 0px);

    }

    &-btn {
        width: ac(86px, 48px);
        height: ac(86px, 48px);
        transform: translate(5%, -23%);
        transition: all 0.3s;
        font-size: ac(35px, 17px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: ac(9px, 4px);
        border: ac(4px, 2px) solid var(--white);


        &:hover {
            color: var(--white);
            background: var(--cyan);
            border-color: var(--cyan);
        }
    }

    &:hover {
        .profile-card-thumbnail {
            img {
                transform: scale(1.5);
            }
        }
    }
}

.job-results-section {
    padding-top: 36px;
    padding-bottom: ac(121px, 50px);

    .more-btn {
        margin: 71px auto 0;
    }
}

.job-results-form {
    background: var(--white);
    border-radius: 9px;
    padding: 20px;

    &-fields {
        width: 100%;
        display: flex;
        padding: 10px;
        border-radius: inherit;
        background: var(--light-gray);
        border: 1px solid var(--dark-gray-rgb);
    }

    input {
        padding: 0 16px;
        flex-grow: 1;
        border-right: 1px solid var(--dark-gray-rgb);
    }

    .meta-select {
        width: 23.1%;
        border-right: 1px solid var(--dark-gray-rgb);
        padding-right: 10px;

        &.location {
            width: 23.1%;
        }
    }

    .link-btn {
        margin-left: ac(39px, 15px);
        margin-right: ac(19px, 0px);
    }
}

.job-results-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 19px;
    padding-top: 60px;
}

.job-card {
    background: var(--cyan);
    transition: 0.3s;
    border-radius: 9px;
    padding: 26px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &-icons {
        position: relative;
        margin-bottom: ac(26px, 18px);

        img {
            width: 58px;
            max-height: 42px;
            transition: opacity 0.3s;

            &:last-of-type {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                opacity: 0;
            }
        }
    }

    &-title {
        font-size: ac(22px, 20px);
        font-weight: 700;
        line-height: 1.18;
        margin-bottom: 24px;
        @mixin max-line-length 5;
    }

    &-info {
        list-style: none;
        padding: 0;
        margin: auto 0 36px;


        span {
            font-weight: 700;
            display: inline-block;
            margin-right: 12px;
        }

        li {
            margin-bottom: 11px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &:hover {
        background: var(--black);
        color: var(--white);

        .job-card-title {
            text-decoration: underline;
            text-underline-offset: 5px;
        }

        .job-card-icons {
            img {
                opacity: 0;

                &:last-of-type {
                    opacity: 1;
                }
            }
        }
    }

    &-thumbnail {
        flex-shrink: 0;
        width: 44px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 11px;

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-user {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
    }
}

@media (max-width: 1100px) {
    .job-results-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1024px) {
    .top-banner-section {
        .banner-label {
            width: 120px;
        }

    }
}

@media (max-width: 992px) {
    .job-results-form {
        padding-top: 30px;

        &-fields {
            flex-direction: column;
            background: var(--white);
            border: 0;
            padding: 0;
            gap: 20px;
        }

        input, .meta-select .choices__inner {
            padding: 10px;
            border: 1px solid var(--dark-gray-rgb);
            background: var(--light-gray);
            border-radius: 9px;
        }

        .meta-select {
            width: 100% !important;
            max-width: 100% !important;
            padding: 0;
            border: 0;

            .choices__list .choices__item {
                padding-right: 30px;
            }

            .choices[data-type*="select-one"]:after, .meta-select .choices[data-type*="select-multiple"]:after {
                right: 15px;
            }
        }

        .link-btn {
            margin-left: auto;
        }
    }
}

@media (max-width: 768px) {
    .job-results-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 550px) {
    .job-results-grid {
        grid-template-columns: 1fr;
    }

    .top-banner-section {
        .title {
            padding-top: 0;
        }

        .cont {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 5px;
        }

        .banner-label {
            align-self: flex-end;
        }
    }
}