@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?8n3jhd');
  src:  url('../../assets/fonts/icomoon.eot?8n3jhd#iefix') format('embedded-opentype'),
  url('../../assets/fonts/icomoon.woff2?8n3jhd') format('woff2'),
  url('../../assets/fonts/icomoon.ttf?8n3jhd') format('truetype'),
  url('../../assets/fonts/icomoon.woff?8n3jhd') format('woff'),
  url('../../assets/fonts/icomoon.svg?8n3jhd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e90a";
}
.icon-play:before {
  content: "\e909";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e904";
}
.icon-chevron-left:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-chevron-up:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e908";
}
